import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ICONS,
  Icon,
  Button,
  P,
  SimpleModalWrapper,
  SimpleModalContent
} from '@sellpy/design-system-react-web'
import { useTranslation } from 'react-i18next'
import { dynamicDescription } from '@sellpy/commons'
import { useQuery } from '@apollo/client'
import { hideEditFavoriteModal } from '../../../common/favorites/actions.js'
import { FavoriteToggleIcon } from '../../components/favoriteButton/FavoriteButtonInTile.jsx'
import useFavorites from '../../components/favoriteButton/useFavorites.jsx'
import { locale, localizedMetadata } from '../../../common/region/locale.js'
import { currentUserSelector } from '../../../common/user/selectors.js'
import { CreateOrUpdateFavoriteListForm } from './CreateFavoritesList.jsx'
import { GET_FAVORITE_LISTS } from './filters/FavoritesListSelector.jsx'

const EditFavoriteModal = () => {
  const { favorites } = useFavorites()
  const [editOrCreate, setEditOrCreate] = useState('edit')
  const { t } = useTranslation('favorite')
  const dispatch = useDispatch()
  const itemId = useSelector((state) => state.favorites.activeIdFavoriteModal)

  const favorite = favorites?.find(({ item }) => item.objectId === itemId)

  return (
    <SimpleModalWrapper
      isOpen={Boolean(itemId)}
      headline={t('editFavorite.headline')}
      onClose={() => {
        dispatch(hideEditFavoriteModal())
        setEditOrCreate('edit')
      }}
    >
      {favorite && (
        <SimpleModalContent>
          <P design='body1'>
            {dynamicDescription.itemSubtitle({
              metadata: favorite.item[localizedMetadata],
              locale: locale
            })}
          </P>
          {editOrCreate === 'create' ? (
            <CreateOrUpdateFavoriteListForm
              t={t}
              afterSubmit={() => setEditOrCreate('edit')}
              onClose={() => {
                dispatch(hideEditFavoriteModal())
                setEditOrCreate('edit')
              }}
            />
          ) : (
            <FavoriteListSelector setEditOrCreate={setEditOrCreate} favorite={favorite} />
          )}
        </SimpleModalContent>
      )}
    </SimpleModalWrapper>
  )
}

export default EditFavoriteModal

const FavoriteListSelector = ({ favorite, setEditOrCreate }) => {
  const { t } = useTranslation('favorite')
  const { editListForFavorite } = useFavorites()
  const dispatch = useDispatch()
  const [inLists, setInLists] = useState(
    favorite?.itemFavoriteLists?.map(({ objectId }) => objectId) || []
  )

  const currentUserId = useSelector((state) => state.user && currentUserSelector(state))
  const { data: favoriteListsData } = useQuery(GET_FAVORITE_LISTS, {
    variables: { userId: currentUserId },
    skip: !currentUserId
  })
  const favoriteLists = favoriteListsData?.itemFavoriteLists.edges?.map(({ node }) => node)

  const onToggle = async (favouriteListId) => {
    const newInLists = inLists.includes(favouriteListId)
      ? [...inLists].filter((id) => id !== favouriteListId)
      : [...inLists, favouriteListId]
    setInLists(newInLists)
    editListForFavorite({ itemId: favorite?.item?.objectId, listIds: newInLists })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Button
          onClick={() => setEditOrCreate('create')}
          style={{ marginBottom: '12px' }}
          label={t('editFavorite.createListButton')}
          rightIcon={<Icon name={ICONS.ADD} />}
          color={'grey'}
        />
      </div>
      <div style={{ borderTop: '1px solid rgba(0,0,0,0.1)', paddingTop: '8px' }}>
        {favoriteLists?.map((favoriteList) => (
          <FavoriteListToggle
            key={favoriteList.objectId}
            selected={inLists.includes(favoriteList.objectId)}
            favouritListId={favoriteList.objectId}
            favoriteListName={favoriteList.name}
            onToggle={onToggle}
          />
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
          <Button
            onClick={() => dispatch(hideEditFavoriteModal())}
            label={t('editFavorite.close')}
          />
        </div>
      </div>
    </>
  )
}

const FavoriteListToggle = ({ selected, favouritListId, favoriteListName, onToggle }) => {
  return (
    <div
      key={favouritListId}
      style={{
        display: 'flex',
        padding: '8px 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
      }}
    >
      <P variant='body1'>{favoriteListName}</P>
      <FavoriteToggleIcon
        filled={selected}
        type='button'
        onClick={() => onToggle(favouritListId)}
        color='grey'
        icon={<Icon name={selected ? ICONS.HEART_FILLED : ICONS.HEART} />}
      />
    </div>
  )
}
