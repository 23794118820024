query getUnverifiedPayOutAndBankAccount($status: String!) {
  unverifiedPayOuts: payOuts(where: { status: { equalTo: $status } }, first: 1) {
    edges {
      node {
        objectId
        accountNumber
        IBAN
      }
    }
  }

  unverifiedBankAccounts: bankAccounts(where: { validTo: { exists: false } }, first: 1) {
      edges {
        node {
          objectId
          accountNumber
          IBAN
        }
      }
    }
}
