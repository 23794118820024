import React from 'react'
import { useSelector } from 'react-redux'
import { Link, matchRoutes, useLocation } from 'react-router-dom'
import { user as userTools } from '@sellpy/commons'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { ICONS, Icon } from '@sellpy/design-system-react-web'
import { useNotifications } from '../../../common/hooks/useNotifications'
import { useResponsive } from '../../hooks'
import Menu from '../../menu/Menu.jsx'
import SellpyLogo from '../../components/ui/icons/SellpyLogo.jsx'
import PromoBanner from '../PromoBanner.jsx'
import { isFeatureEnabled, FEATURE_SELL } from '../../featureToggle'
import { getItemsInCart } from '../../../common/entities/selectors/checkout'
import { CHECKOUT_ROUTES } from '../../checkoutV2/utils/constants'
import { AB_TESTS, inAbTestTestGroup } from '../../../common/analytics/abTest'
import {
  Wrapper,
  FlexContainer,
  HeaderPosition,
  NavWrapper,
  NavEntries,
  QuickLinkItem,
  QuickLinkContent,
  NavItem,
  NavFlexContainer,
  NavContent,
  NavLabel,
  StyledNotificationBadge,
  SearchboxWrapper,
  GreyBorder
} from './Styles.jsx'
import AppDownloadBanner from './components/AppDownloadBanner.jsx'
import Searchbox from './components/searchbox/Searchbox.jsx'
import SellButton from './components/SellButton.jsx'
import WhiteSpace from './components/WhiteSpace.jsx'

const getActiveNavEntry = (pathname = '/') => {
  const urlSegments = pathname.split('/')
  const firstParameter = urlSegments[1]
  const secondParameter = urlSegments[2]
  // user/<secondParameter>
  if (firstParameter === 'user') {
    return (
      {
        sales: 'sell',
        circle: 'sell'
      }[secondParameter] || 'buy'
    )
  }
  return (
    {
      // BUY
      store: 'buy',
      '': 'buy',
      search: 'buy',
      women: 'buy',
      men: 'buy',
      children: 'buy',
      things: 'buy',
      hmBag: 'sell',
      item: 'buy',
      // SELL
      'hm-pickup': 'sell',
      sell: 'sell',
      howItWorks: 'sell',
      pricingInfo: 'sell',
      whatWelSell: 'sell',
      circle: 'sell',
      about: 'sell',
      sales: 'sell',
      balance: 'sell',
      'order-bag': 'sell',
      orderPickUp: 'sell',
      requestPayout: 'sell',
      'start-item-sale': 'sell',
      'my-item': 'sell',
      flattered: 'sell'
    }[firstParameter] || 'buy'
  )
}

const shouldShowPromoBanner = (location) => {
  const routesToMatch = [
    { path: '/', end: true },
    { path: '/men', end: true },
    { path: '/women', end: true },
    { path: '/children', end: true },
    { path: '/things', end: true },
    { path: '/item/:itemId/:slug?', end: true },
    { path: '/search', end: true },
    { path: '/checkout/cart', end: true },
    { path: '/upcycled', end: true }
  ]
  const matches = matchRoutes(routesToMatch, location)
  return Boolean(matches)
}

const Header = () => {
  const location = useLocation()
  const { pathname } = location
  const theme = useTheme()
  const { t } = useTranslation('common')
  const parseUser = useSelector((state) => state.user.parseUser)
  const customerImpersonation = useSelector((state) => state.entities.CustomerImpersonation.size)
  const loggedIn = userTools.userIsFull(parseUser)
  const activeNavEntry = getActiveNavEntry(pathname)
  const notificationsCount = useNotifications().size
  const itemsInCartCount = useSelector((state) => getItemsInCart(state)).size
  const { isMobile, isTablet } = useResponsive()
  const showPromoBanner = shouldShowPromoBanner(location)
  const navSections = [
    {
      key: 'buy',
      label: t('header.buy'),
      to: '/'
    },
    {
      key: 'sell',
      label: t('header.sell'),
      to: '/sell',
      feature: FEATURE_SELL
    }
  ]
  const navIconStyle = { fontSize: '2rem', color: theme.color.grey.shade2 }

  const navEntries = [
    {
      key: 'favorites',
      to: inAbTestTestGroup(AB_TESTS.myShoppingLink) ? '/my-shopping' : '/my-shopping/favorites',
      icon: <Icon name={ICONS.HEART} style={navIconStyle} />
    },
    {
      key: 'home',
      to: loggedIn ? '/home' : '/login',
      icon: <Icon name={ICONS.PROFILE} style={navIconStyle} />,
      requiredFeature: loggedIn,
      badgeCount: notificationsCount
    },
    {
      key: 'cart',
      to: '/checkout/cart',
      icon: <Icon name={ICONS.CART} style={navIconStyle} />,
      badgeCount: itemsInCartCount,
      badgeColor: theme.color.blue.default
    }
  ]

  const paths = [
    '/sell',
    '/sell-signup',
    '/howItWorks',
    '/pricingInfo',
    '/circle',
    '/about',
    '/hmBag',
    '/sale',
    '/hm-pickup',
    '/user/circle',
    '/sales',
    '/user/orders',
    '/itemEdit',
    '/my-shopping',
    '/flattered',
    '/sellpy-labs/search',
    '/withdraw',
    '/payout-setup',
    '/payout-setup/charity',
    '/payout-setup/bankAccount',
    '/payout-setup/confirmation',
    CHECKOUT_ROUTES.CART_ROUTE,
    CHECKOUT_ROUTES.VALIDATE_EMAIL_ROUTE,
    CHECKOUT_ROUTES.ADDRESS_ROUTE,
    CHECKOUT_ROUTES.SHIPPING_ROUTE,
    CHECKOUT_ROUTES.SUMMARY_ROUTE,
    CHECKOUT_ROUTES.PAYMENT_ROUTE
  ]

  if (pathname.includes('/sale/')) {
    paths.push(pathname)
  }

  const hideSearchBarForMobile = paths.includes(pathname) && isTablet

  return (
    <>
      <WhiteSpace hideSearchBarForMobile={hideSearchBarForMobile} />
      <HeaderPosition id='topHeader'>
        <AppDownloadBanner />
        {showPromoBanner && <PromoBanner />}
        <Wrapper
          customerImpersonation={customerImpersonation}
          hideSearchBarForMobile={hideSearchBarForMobile}
        >
          <FlexContainer>
            <Menu />
            <Link to='/' style={{ display: 'flex' }} aria-label='Sellpy'>
              <SellpyLogo color={theme.color.grey.shade2} style={{ width: isMobile && 73 }} />
            </Link>
          </FlexContainer>
          {!isTablet && (
            <NavWrapper>
              {navSections.map(
                ({ key, label, to, feature }) =>
                  isFeatureEnabled(feature) && (
                    <NavItem key={key} to={to} active={activeNavEntry === key}>
                      <NavFlexContainer>
                        <NavContent>
                          <NavLabel active={activeNavEntry === key} center noMargin>
                            {label}
                          </NavLabel>
                        </NavContent>
                      </NavFlexContainer>
                    </NavItem>
                  )
              )}
            </NavWrapper>
          )}
          {!hideSearchBarForMobile && (
            <SearchboxWrapper>
              <Searchbox />
            </SearchboxWrapper>
          )}
          <NavEntries>
            {navEntries.map(({ key, to, icon, badgeCount, badgeColor }) => (
              <QuickLinkItem key={key} to={to}>
                <QuickLinkContent>
                  <div>{icon}</div>
                  {badgeCount ? (
                    <StyledNotificationBadge count={badgeCount} badgeColor={badgeColor} />
                  ) : null}
                </QuickLinkContent>
              </QuickLinkItem>
            ))}
            {isFeatureEnabled(FEATURE_SELL) && isTablet && (
              <SellButton navIconStyle={navIconStyle} />
            )}
          </NavEntries>
          <GreyBorder />
        </Wrapper>
      </HeaderPosition>
    </>
  )
}

export default Header
