import React from 'react'
import CookieSettingsModal from '../profile/CookieSettingsModal.jsx'
import { DataConsentBanner } from '../profile/DataConsentBanner.jsx'
import { CartFooter } from '../checkoutV2/cart/components/CartFooter.jsx'

const PersistantFooterStack = () => (
  <>
    <CookieSettingsModal />
    <DataConsentBanner />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        bottom: '-1px',
        right: 0,
        left: 0,
        zIndex: 1
      }}
    >
      <CartFooter />
    </div>
  </>
)

export default PersistantFooterStack
