query getLatestBankAccount {
  bankAccounts(order: createdAt_DESC, first: 1) {
    edges {
      node {
        objectId
        accountNumber
        clearingNumber
        BIC
        IBAN
      }
    }
  }
}
