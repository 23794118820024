import React from 'react'
import {
  externalReviewService,
  trustPilotLink,
  languageLocalisationCode,
  trustPilotBusinessUnitId
} from '../../../../../common/region/config'

const TrustScore = () => {
  return {
    TrustedShops: <div id='MyCustomTrustbadge' style={{ width: '160px', height: '80px' }} />,
    TrustPilot: (
      <div
        className='trustpilot-widget'
        data-locale={languageLocalisationCode}
        data-template-id='5419b637fa0340045cd0c936'
        data-businessunit-id={trustPilotBusinessUnitId}
        data-style-height='20px'
        data-style-width='230px'
        data-theme='light'
        data-tags='SelectedReview'
      >
        <a href={trustPilotLink} target='_blank' rel='noopener noreferrer'>
          Trustpilot
        </a>
      </div>
    ),
    TrustProfile: (
      <iframe
        src='https://dashboard.trustprofile.com/webshops/widget_html?id=4030909&layout=new_default&theme=dark&color=%234655D2&show=no&view=slider&amount=6&width=manual&width_amount=230px&height=78px&interval=5000&language=nld'
        style={{ border: 0, height: '75px' }}
      />
    )
  }[externalReviewService]
}

export default TrustScore
