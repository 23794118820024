query getActiveSales($region: String!, $userId: ID!, $after: String) {
  bags(
    where: {
      AND: {
        OR: [
          { status: { in: [8, 10] }, presortingStartedAt: { exists: true } }
          { status: { equalTo: 2 } }
        ]
        presortingStartedAt: { exists: true }
        user: { have: { objectId: { equalTo: $userId } } }
        region: { equalTo: $region }
      }
    }
    first: 3
    after: $after
    order: createdAt_DESC
  ) {
    edges {
      node {
        objectId
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
