query activeSalesTabOverview($userId: ID!, $cursor: String) {
  active: bags(
    where: {
      AND: {
        OR: [
          { status: { in: [8, 10] }, presortingStartedAt: { exists: true } }
          { status: { equalTo: 2 } }
        ]
        user: { have: { objectId: { equalTo: $userId } } }
      }
    }
    first: 4
    after: $cursor
    order: createdAt_DESC
  ) {
    edges {
      node {
        objectId
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    count
  }
}
