import { useEffect } from 'react'
import Parse from 'parse'
import { useDispatch } from 'react-redux'
import { tryLoggedIn } from '../../common/user/actions'
import { useQuery } from '../hooks'
import { setReferralCode } from '../../common/sale/actions'

const useSetReferralCode = () => {
  const query = useQuery()
  const dispatch = useDispatch()
  if (query.referralCode) dispatch(setReferralCode(query.referralCode))
}

const UserWrapper = ({ children }) => {
  const dispatch = useDispatch()
  useSetReferralCode()

  useEffect(() => {
    const handleRejection = (event) => {
      if (event?.reason?.code === Parse.Error.INVALID_SESSION_TOKEN) {
        Parse.User.logOut().finally(() => {
          window.location.replace(window.location.origin + '/login')
        })
      }
    }
    window.addEventListener('unhandledrejection', handleRejection)

    return () => window.removeEventListener('unhandledrejection', handleRejection)
  }, [])

  useEffect(() => {
    dispatch(tryLoggedIn())
  }, [dispatch])

  return children
}

export default UserWrapper
