import { defer } from 'react-router-dom'
import { ITEM_STATUS } from '@sellpy/commons'
import { preloadQuery } from '../../apollo/apolloClientSingleton.js'
import { locale } from '../../../common/region/locale.js'
import { getSale } from './salesControl.gql'
import {
  createAwaitingApprovalQuery,
  createDonatedQuery,
  createProcessingQuery,
  createPublishedQuery,
  createReEvaluationRequestedQuery,
  createSentBackQuery,
  createSoldQuery,
  createSortedForRecyclingQuery,
  ITEM_COUNT_QUERY,
  ITEM_PER_PAGE,
  localeVariables
} from './graphql/queries'

export const salesControlLoader = async (saleId) => {
  const getSaleQuery = preloadQuery(getSale, {
    variables: {
      saleId
    }
  })

  const publishedCountQuery = preloadQuery(ITEM_COUNT_QUERY, {
    variables: {
      saleId,
      statuses: [ITEM_STATUS.UTLAGD]
    }
  })

  const awaitingApprovalQuery = preloadQuery(
    createAwaitingApprovalQuery({
      locale,
      itemPerPage: ITEM_PER_PAGE
    }),
    {
      variables: {
        saleId,
        ...localeVariables(locale)
      }
    }
  )

  const publishedQuery = preloadQuery(
    createPublishedQuery({
      locale,
      itemPerPage: ITEM_PER_PAGE
    }),
    {
      variables: {
        saleId,
        ...localeVariables(locale)
      }
    }
  )

  const sortedForRecyclingQuery = preloadQuery(
    createSortedForRecyclingQuery({
      locale,
      itemPerPage: ITEM_PER_PAGE
    }),
    {
      variables: {
        saleId,
        ...localeVariables(locale)
      }
    }
  )

  const soldQuery = preloadQuery(
    createSoldQuery({
      locale,
      itemPerPage: ITEM_PER_PAGE
    }),
    {
      variables: {
        saleId,
        ...localeVariables(locale)
      }
    }
  )

  const reEvaluationRequestedQuery = preloadQuery(
    createReEvaluationRequestedQuery({
      locale,
      itemPerPage: ITEM_PER_PAGE
    }),
    {
      variables: {
        saleId,
        ...localeVariables(locale)
      }
    }
  )

  const processingQuery = preloadQuery(
    createProcessingQuery({
      locale,
      itemPerPage: ITEM_PER_PAGE
    }),
    {
      variables: {
        saleId,
        ...localeVariables(locale)
      }
    }
  )

  const donatedQuery = preloadQuery(
    createDonatedQuery({
      locale,
      itemPerPage: ITEM_PER_PAGE
    }),
    {
      variables: {
        saleId,
        ...localeVariables(locale)
      }
    }
  )

  const sentBackQuery = preloadQuery(
    createSentBackQuery({
      locale,
      itemPerPage: ITEM_PER_PAGE
    }),
    {
      variables: {
        saleId,
        ...localeVariables(locale)
      }
    }
  )

  return defer({
    values: Promise.all([
      getSaleQuery,
      publishedCountQuery,
      awaitingApprovalQuery,
      publishedQuery,
      sortedForRecyclingQuery,
      soldQuery,
      reEvaluationRequestedQuery,
      processingQuery,
      donatedQuery,
      sentBackQuery
    ]).then(
      ([
        sale,
        publishedCountData,
        awaitingApproval,
        published,
        sortedForRecycling,
        sold,
        reEvaluationRequested,
        processing,
        donated,
        sentBack
      ]) => ({
        sale,
        publishedCountData,
        awaitingApproval,
        published,
        sortedForRecycling,
        sold,
        reEvaluationRequested,
        processing,
        donated,
        sentBack
      })
    )
  })
}
