query getItemsPreviouslyInCart($userId: ID!, $oldestDate: Date!) {
  itemReservations(
    where: {
      AND: [
        { user: { have: { objectId: { equalTo: $userId } } } }
        { createdAt: { greaterThan: $oldestDate } }
      ]
    }
    order: createdAt_DESC
    first: 500
  ) {
    edges {
      node {
        createdAt
        status
        item {
          ... on Item {
            objectId
          }
        }
      }
    }
  }
}
