query getLatestItemRecommendationsForUser($userId: ID!) {
  itemRecommendationsForUsers(where: { user: { have: { objectId: { equalTo: $userId } } } }) {
    edges {
      node {
        items {
          ... on Item {
            objectId
          }
        }
      }
    }
  }
}
