query getTrackedSearchesForUser($userId: ID!, $region: String!) {
  trackedSearches(
    order: createdAt_DESC
    where: { 
      AND: {
        OR: [
          {status: {equalTo: "active"}}
          {status: {equalTo: "paused"}}
        ]
        user: { have: { objectId: { equalTo: $userId } } } 
        region: { equalTo: $region } 
      }
    }
  ) {
    edges {
      node {
          objectId
          searchPath
          name
          status
      }
    }
  }
}
