import config from 'config'
const { elasticMiddleware } = config

export default async function runRequest(body) {
  // eslint-disable-next-line no-undef
  const response = await fetch(elasticMiddleware.url, {
    method: 'POST',
    body: JSON.stringify(body)
  })
  if (!response.ok) {
    console.error('runRequest to elasticMiddleware failed')
  }
  return response.json()
}
