query receivedSalesTabOverview($userId: ID!, $cursor: String) {
  received: bags(
    where: {
      user: { have: { objectId: { equalTo: $userId } } }
      status: { in: [8, 10] }
      presortingStartedAt: { exists: false }
    }
    first: 4
    after: $cursor
    order: createdAt_DESC
  ) {
    edges {
      node {
        objectId
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    count
  }
}
