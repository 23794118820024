query endedSalesOverview($userId: ID!, $cursor: String) {
  ended: bags(
    where: { user: { have: { objectId: { equalTo: $userId } } }, status: { equalTo: 5 } }
    first: 4
    after: $cursor
    order: createdAt_DESC
  ) {
    edges {
      node {
        objectId
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    count
  }
}
