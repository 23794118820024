query getSalesOverviewData(
  $userId: ID!
  $p2pTransactionTypes: [String]!
  $independentTransactionTypes: [String]!
  $currency: String!
  $region: String!
) {
  compensations: balanceTransactions(
    where: {
      type: { in: $independentTransactionTypes }
      user: { have: { objectId: { equalTo: $userId } } }
      region: { equalTo: $region }
    }
    first: 5000
    order: createdAt_DESC
  ) {
    edges {
      node {
        objectId
        delta
      }
    }
  }
  p2p: balanceTransactions(
    where: {
      type: { in: $p2pTransactionTypes }
      user: { have: { objectId: { equalTo: $userId } } }
    }
    first: 5000
    order: createdAt_DESC
  ) {
    edges {
      node {
        objectId
        delta
      }
    }
  }
  p2pItemsLive: items(
    where: {
      user: { have: { objectId: { equalTo: $userId } } }
      p2p: { equalTo: true }
      itemStatus: { equalTo: "utlagd" }
    }
  ) {
    count
  }
  p2pItems: items(
    where: { user: { have: { objectId: { equalTo: $userId } } }, p2p: { equalTo: true } }
  ) {
    count
  }
  getTotalBalanceForUser(currency: $currency)
}
