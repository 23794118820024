query getSalesOverview($userId: ID!) {
  active: bags(
    where: {
      AND: {
        OR: [
          { status: { in: [8, 10] }, presortingStartedAt: { exists: true } }
          { status: { equalTo: 2 } }
        ]
        user: { have: { objectId: { equalTo: $userId } } }
      }
    }
  ) {
    count
  }
  received: bags(
    where: {
      user: { have: { objectId: { equalTo: $userId } } }
      status: { in: [8, 10] }
      presortingStartedAt: { exists: false }
    }
  ) {
    count
  }
  ended: bags(
    where: { user: { have: { objectId: { equalTo: $userId } } }, status: { equalTo: 5 } }
  ) {
    count
  }
}
