import React from 'react'
import { useHeaderHeight } from '../../../hooks'

const WhiteSpace = ({ hideSearchBarForMobile }) => {
  const headerHeight = useHeaderHeight(hideSearchBarForMobile)

  return (
    <div
      style={{
        paddingTop: headerHeight
      }}
    />
  )
}

export default WhiteSpace
