query automaticPayoutConfig($currency: String!) {
  automaticPayoutConfigs(
    where: { active: { equalTo: true }, currency: { equalTo: $currency } }
    first: 1
    order: createdAt_DESC
  ) {
    edges {
      node {
        objectId
        bankAccount {
          accountNumber
          clearingNumber
          IBAN
          BIC
        }
        credits
        charityOrganization {
          objectId
          name
          key
        }
      }
    }
  }
}