query getCharityOrganizationsByRegion($region: String!) {
  charityOrganizations: charityOrganizations(
    where: { region: { equalTo: $region }, active: { equalTo: true } }
  ) {
    edges {
      node {
        objectId
        name
        key
      }
    }
  }
}
