query getAllStores($region: String!) {
  allCuratedStore(
    where: { region: { eq: $region }, excludeFromStoresPage: { eq: false } }
    sort: { _createdAt: DESC }
  ) {
    _id
    title
    curations {
      _id
    }
    urlSlug {
      current
    }
    image {
      asset {
        url
      }
    }
  }
  allUserStore(
    where: { region: { eq: $region }, excludeFromStoresPage: { eq: false } }
    sort: { _updatedAt: DESC }
  ) {
    _id
    title
    tags
    owner
    urlSlug {
      current
    }
    image {
      asset {
        url
      }
    }
  }
  allSelectionStore(
    where: { region: { eq: $region }, excludeFromStoresPage: { eq: false } }
    sort: { _updatedAt: DESC }
  ) {
    _id
    title
    urlSlug {
      current
    }
    image {
      asset {
        url
      }
    }
  }
  allCollectionStore(
    where: { region: { eq: $region }, excludeFromStoresPage: { eq: false } }
    sort: { _updatedAt: DESC }
  ) {
    _id
    title
    urlSlug {
      current
    }
    image {
      asset {
        url
      }
    }
  }
}
