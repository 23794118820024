query getSale($saleId: ID!) {
  bag(id: $saleId) {
    objectId
    createdAt
    dateClosed
    status
  }
  containers(where: { bag: { have: { objectId: { equalTo: $saleId } } } }) {
    edges {
      node {
        dateProcessed
      }
    }
    count
  }
}
