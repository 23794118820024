import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { SEGMENT_CHOICES_ALL } from '../landing/filterHelperFunctions'
import Header from '../app/Header/Header.jsx'
import Footer from '../app/Footer/Footer.jsx'
import theme from '../uiComponents/mergeThemes'

const useScrollToTop = () => {
  const { pathname } = useLocation()
  const prevPathname = useRef()

  const landingPageNotScrollToTop = (pathname, prevPathname) => {
    const landingPaths = SEGMENT_CHOICES_ALL.map((segment) => `/${segment}`)
    landingPaths.push('/')
    return !landingPaths.includes(pathname) || !landingPaths.includes(prevPathname)
  }

  useEffect(() => {
    if (landingPageNotScrollToTop(pathname, prevPathname.current) && pathname !== prevPathname) {
      /**
       * On all modern browsers (inkl. Safari on iOS) scroll restoration seems to work fine when navigating back/forward in the browser.
       * However, in Safari on iOS, when navigating to a new page (e.g via a link), the scroll is not reset to top of page. The scroll
       * is instead (wrongly) persisted between pages. To solve this `window.scrollTo(0, 0)` is added to all navigation changes.
       * At a first glance, this seems to break the scroll restoration when navigating back/forward, but for some reason that still works.
       * We're not sure if the browser disregards the window.scrollTo(0, 0) or if the browser's own restoration is applied right after the code,
       * but it works regardless :)
       */
      window.scrollTo(0, 0)
    }
    prevPathname.current = pathname
  }, [pathname])
}

const useZoomForMobile = () => {
  useEffect(() => {
    // This effect is only to make sure that iphones doesn't auto-zoom when a user clicks on a text-field.
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
    if (isIOS) {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1')
    } else {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute('content', 'width=device-width, initial-scale=1')
    }
  }, [])
}

export const Layout = ({ children }) => {
  useZoomForMobile()
  useScrollToTop()

  if (window.appUpdateAvailable === true) {
    window.location.reload()
    return null
  }

  return (
    <div
      style={{
        fontFamily: theme.text.fonts.body,
        fontWeight: 400,
        color: theme.color.grey.shade2,
        backgroundColor: 'white'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          minHeight: '100vh'
        }}
      >
        <Header />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            width: '100%'
          }}
        >
          <main style={{ width: '100%' }}>{children}</main>
        </div>
        <Footer />
      </div>
      <div id='portal-root' />
    </div>
  )
}

export default Layout
