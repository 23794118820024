import React from 'react'
import config from 'config'
import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'

export const GlobalMetaTagPlacer = () => {
  const [searchParams] = useSearchParams()
  const shouldNotIndex = ['canary', 'dev'].includes(config.environment) || searchParams.get('query')
  return (
    <Helmet>
      {shouldNotIndex && <meta name='robots' content='noindex' />}
      {process.env.REGION === 'FR' && (
        <meta name='france-verif-confirmation' content='a5ca5ff27b74a94edccdaf7a95bd1e65' />
      )}
    </Helmet>
  )
}
